import process from 'process';

export const routes = {
  main: '/:guid',
  mainru: '/ru/:guid',
  mainTapPayment: '/t/:guid',
  confirm: '/confirm',
  legalInfoRoutes: {
    oferta: `/oferta`,
    companyDetails: `/company-details`,
    returnPolicy: `/return-policy`,
    paymentProcess: `/payment-process`,
  },
};
export const stripePubKey = process.env.REACT_APP_STRIPE_PUB_KEY || '';

export const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

export const assetsUrl = process.env.REACT_APP_ASSETS_URL;

export const modulbankId = process.env.REACT_APP_MODULBANK_ID;

export const modulbankTest = process.env.REACT_APP_MODULBANK_TEST;

export const modulbankSuccessUrl = process.env.REACT_APP_MODULBANK_SUCCESS_URL;

export const tapPaymentPubKey = process.env.REACT_APP_TAP_PAYMENT_PUB_KEY || '';

export const modulbankCallBackUrl = `${process.env.REACT_APP_API_URL}/api/bookings/confirmru`;

export const rubPayPageUrl = process.env.REACT_APP_RUB_PAY_PAGE || '';

// @ts-ignore
export const getAssetsUrl = () => process.env.REACT_APP_ASSETS_URL || window.assetsUrl || '';
export const emptyDate = new Date(2020, 1, 1, 0, 0, 0);
