import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { rubPayPageUrl } from '../../config/constants';
import round from '../../helpers/round';
interface ModulBankBooking {
  id: number;
  guid: string;
  date: string;
  tourName: string;
  offerName: string;
  guestName: string;
  email: string;
  hotelName: string;
  roomNumber: string;
  phone: string;
  numAdults: number;
  numChildren: number;
  numInfants: number;
  numFoc: number;
  isPayable: boolean;
  isSupplierVoucher: boolean;
  total: number;
  totalDue: number;
  totalVat: number;
  totalCollection: number;
  totalPayable: number;
  totalPayableUsd: number;
  pricePerAdult: number;
  pricePerChild: number;
  pricePerTrip: number;
}
type Props = {
  booking: ModulBankBooking;
  amount: number;
  amountUsd: number;
  convRate: number;
  ticketType: string;
  isValid: boolean;
  modulbankId: string;
  modulbankSuccessUrl: string;
  modulbankTest?: string;
  legalInfoPaymentProcessUrl: string;
};

const PaymentModulbank = ({ booking, amount, isValid, modulbankId, legalInfoPaymentProcessUrl }: Props) => {
  const merchantId = modulbankId;

  const amountCard = useMemo(() => round(amount * 1.02), [amount]);

  const handleQrFormSubmit = async (e: React.FormEvent) => {
    debugger;
    window.location.href = `${rubPayPageUrl}/${booking?.guid}?paytype=sbp&successurl=${window.location.host}/confirm/${booking.id}`;
  };
  const handleCardFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    window.location.href = `${rubPayPageUrl}/${booking?.guid}?paytype=card&successurl=${window.location.host}/confirm/${booking.id}`;
  };

  return merchantId ? (
    <>
      <div className="payment-button-container">
        <button
          onClick={handleQrFormSubmit}
          disabled={!isValid}
          className="button button_fullWidth button_blue button_medium"
        >
          Оплатить через СБП {amount} руб.
        </button>
      </div>

      <div className="payment-button-container">
        <button
          onClick={handleCardFormSubmit}
          disabled={!isValid}
          className="button button_fullWidth button_blue button_medium"
        >
          Оплатить картой РФ {amountCard} руб. (+2%)
        </button>
      </div>

      <ul className="checkout-summary-list">
        <li>
          <Link to={legalInfoPaymentProcessUrl}>Порядок проведения оплаты и возвратов по банковским картам</Link>
        </li>
      </ul>
    </>
  ) : (
    <p>"Не задана конфигурация платежного шлюза"</p>
  );
};
export default PaymentModulbank;
